<template>
  <div>
      <DHomeContent />
  </div>
</template>

<script>
import DHomeContent from "@/components/dashboard/DHomeContent.vue";

export default {
  components: {
    DHomeContent
  }
}
</script>

<style>

</style>